import Head from "next/head";

export default function HeadComponent() {
  return (
    <>
      <Head>
        <title>Donations | DMB Canvass</title>
        <link rel="icon" href="/assets/images/dmb-canvas-logo.png" />
        <link
          rel="stylesheet"
          href="https://designs.dmb2024.com/assets/css/canvas.css"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
      </Head>
    </>
  );
}
